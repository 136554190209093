/* html, body {
	background-color: #e12f3b;
	background-image: url('./images/AcademyDesktopGeneric.jpg');
	background-position: center 0;
	background-attachment: fixed;
	min-height: 100%;
} */

.footer {
  margin-top: 2rem;
  text-align: center;
  position: relative;
  clear: both;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
